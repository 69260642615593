.accordion {
  max-width: 800px;
  width: 100%;
  padding: 0 40px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.accordion__heading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

.accordion__content {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.accordion__item {
  border-bottom: 1px solid #ddd;
}

.accordion__item:last-child {
  border-bottom: none;
}

.accordion__question {
  padding: 15px 20px;
  margin: 0;
  background-color: #f7f7f7;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  transition: background-color 0.3s ease;
}

.accordion__question:hover,
.accordion__question.active {
  background-color: #e7e7e7;
}

.accordion__answer {
  display: none;
  padding: 15px 20px;
  background-color: #fff;
  color: #333;
}

.accordion__answer.show {
  display: block;
}
