.pricing-section {
  background-color: #23a1d1;
}

.container {
  padding: 32px;
  max-width: 1280px;
  margin: auto;
}

.title-section {
  text-align: center;
  margin-bottom: 32px;
  color: white;
}

.section-title {
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: bold;
}

.section-subtitle {
  font-size: 32px;
  font-weight: bold;
}

.plans-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.loading-plans {
  margin-top: 32px;
  text-align: center;
  height: 100vh;
  font-size: 24px;
}

.plan {
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.plan:hover {
  transform: scale(1.05);
}

.plan.free-trial {
  border-color: #4ade80;
  position: relative;
}

.plan.free-trial::before {
  content: "FREE TRIAL";
  font-weight: bold;
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #22c55e;
  color: white;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 5px;
}

.plan-title {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 16px;
  text-align: center;
}

.price-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin: 20px 0;
}

.price {
  font-size: 40px;
  font-weight: bold;
  color: #000000;
}

.price-period {
  font-size: 16px;
  color: #6b7280;
  margin-left: 8px;
}

.plan-description {
  margin-bottom: 32px;
}

.description-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.description-checkmark {
  width: 24px;
}

.description-text {
  color: black;
  margin-left: 8px;
}

.get-started-btn {
  background-color: #2563eb;
  color: white;
  text-align: center;
  padding: 8px 20px;
  border-radius: 4px;
  font-weight: medium;
  text-decoration: none;
  display: inline-block;
}

.get-started-btn:hover {
  background-color: #1d4ed8;
}

.checkmark {
  width: 24px;
  height: 24px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
  color: #10b981;
  /* Tailwind green-500 */
}

.error-message {
  color: #ef4444;
  /* Tailwind red-500 */
  text-align: center;
  margin-top: 16px;
}

/* Adjusting to light/dark themes */
.light .pricing-section {
  background-color: #f9fafb;
}

.dark .plan,
.dark .plan-title,
.dark .price,
.dark .price-period,
.dark .description-item span,
.dark .feature-item span {
  color: #ffffff;
  background-color: #1f2937;
  border-color: #374151;
}

.dark .get-started-btn,
.dark .get-started-btn:hover {
  background-color: #4f46e5;
  border-color: #4338ca;
}