.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-top: 100px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #23a1d1;
  color: #eeeeee;
}

.home-heading {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.error {
  color: #d9534f;
  font-size: 0.7rem;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  padding: 5px;
  margin: 10px 0;
}

.home a {
  color: #EDEEEE;
  font-family: 'Ralway Bold', sans-serif;
}

.authenticate-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.authenticate-button:hover {
  background-color: #0056b3;
}