.publish-form-container {
  width: 480px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.publish-form {
  display: flex;
  align-items: center;
}

.publish-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.input-error {
  border-color: rgb(227, 59, 59);
}

.toggle-container {
  display: flex;
  align-items: right;
  margin-bottom: 15px;
  margin-top: 10px;
}

.account-select {
  width: 90%;
  margin: 0 auto;
  color: black;
}

.error-message {
  color: rgb(227, 59, 59);
}

.publish-button {
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.3s;
}

.publish-button:hover {
  background-color: #3e8e41;
}

.publish-button:disabled {
  background-color: #cccccc;
  cursor: default;
}

.authenticate-button {
  margin: 20px 0;
}

.response-message {
  margin-top: 20px;
}